import * as React from "react"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from "gatsby-plugin-image"

export default function Services() {

    const { t } = useTranslation();

    return(

        <section id="mainServices" className="mt-5">

            <div className="container">

                <div className="row">

                    <div className="col-12 text-center mb-4">

                        <h2 className="h1"><Trans>Our Services</Trans></h2>

                    </div>

                    <div className="col-lg-3 text-center">

                        <div className="service-img">
                            <StaticImage
                                src="../images/ico_new_constructions.png"
                                width={100}
                                quality={90}
                                formats={["auto", "webp", "avif"]}
                                alt={t("Residential title")}
                            />
                        </div>

                        <div className="service-text">
                            <h3>{t("Residential title")}</h3>
                            <p>{t("Residential text")}</p>
                        </div>

                    </div>

                    <div className="col-lg-3 text-center">

                        <div className="service-img">
                            <StaticImage
                                src="../images/ico_commercial.png"
                                width={100}
                                quality={90}
                                formats={["auto", "webp", "avif"]}
                                alt={t("Commercial title")}
                            />
                        </div>

                        <div className="service-text">
                            <h3>{t("Commercial title")}</h3>
                            <p>{t("Commercial text")}</p>
                        </div>

                    </div>

                    <div className="col-lg-3 text-center">

                        <div className="service-img">
                            <StaticImage
                                src="../images/ico_restoration.png"
                                width={100}
                                quality={90}
                                formats={["auto", "webp", "avif"]}
                                alt={t("Constructions title")}
                            />
                        </div>

                        <div className="service-text">
                            <h3>{t("Constructions title")}</h3>
                            <p>{t("Constructions text")}</p>
                        </div>

                    </div>

                    <div className="col-lg-3 text-center">

                        <div className="service-img">
                            <StaticImage
                                src="../images/ico_patios.png"
                                width={100}
                                quality={90}
                                formats={["auto", "webp", "avif"]}
                                alt={t("Exterior title")}
                            />
                        </div>

                        <div className="service-text">
                            <h3>{t("Exterior title")}</h3>
                            <p>{t("Exterior text")}</p>
                        </div>

                    </div>

                </div>

            </div>

        </section>

    )

}